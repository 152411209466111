<template>
  <LayoutVertical />
</template>

<script>
import LayoutVertical from "@/feedback-layouts/vertical/LayoutVertical.vue";
import { mapActions } from "vuex";

export default {
  components: {
    LayoutVertical,
  },
   created() {
    this.setNavMenuItems();
  },
  methods: {
    ...mapActions({ setNavMenuItems: "appFeedback/setNavMenuItems" }),
  },
};
</script>

<style>
</style>