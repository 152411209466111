<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder" style="color: #5e5873">
              {{ getUser ? getUser.name : "" }}
            </p>
            <p class="user-name" style="color: #5e5873">
              {{ getUser ? getUser.role.name : "" }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-danger"
            badge
            :src="getUser ? getUser.profile_image : ''"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          @click="changePassword"
          link-class="d-flex align-items-center"
        >
          
          <span>Change Password</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logoutButtonClick"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <change-password-modal
      :key="`createe-${changePasswordModalCount}`"
      @modalClosed="onModalClosedPaswword"
    />
  </div>
</template>

<script>
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import ChangePasswordModal from "@/components/petition/ChangePasswordModal.vue";
import { mapGetters, mapActions } from "vuex";
import store from "@/store";

export default {
  components: {
    DarkToggler,
    ChangePasswordModal,
  },
  data() {
    return {
      changePasswordModalCount: 0,
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    ...mapActions({ logout: "appData/logout" }),
    
    changePassword() {
      this.changePasswordModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("change-password-modal");
      });
    },

    async onModalClosedPaswword() {
      try {
        await this.getUserData();
      } catch (error) {}
    },
    async logoutButtonClick() {
      try {
        const res = await this.logout();
        if (res.status === 204) {
          store.commit("appConfig/UPDATE_SKIN", "light");
          this.$router.push({ name: "LoginMh" });
        }
      } catch (error) {
        this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      hasRole: "appData/hasRole",
      getUser: "appData/getUser",
    }),
  },
};
</script>
